/**
 * Syntax highlighting styles
 */
@use "minima" as *;
@use "minima/base" as *;

$literal-string-color: $brand-color-desaturated;
$literal-number-color: #008080;
$literal-special-color: #990073;
$name-color: #051e61;
$error-color: #a61717;

.highlight {
  @extend %vertical-rhythm;

  // Comment
  .c {
    color: $text-color-light;
    font-style: italic;
  }

  // Error
  .err {
    color: $error-color;
  }

  // Keyword
  .k {
    font-weight: $monospace-font-strong-weight;
  }

  // Operator
  .o {
    font-weight: $monospace-font-strong-weight;
  }

  // Comment.Multiline
  .cm {
    color: $text-color-light;
    font-style: italic;
  }

  // Comment.Preproc
  .cp {
    color: $text-color-light;
    font-weight: $monospace-font-strong-weight;
  }

  // Comment.Single
  .c1 {
    color: $text-color-light;
    font-style: italic;
  }

  // Comment.Special
  .cs {
    color: $text-color-light;
    font-weight: $monospace-font-strong-weight;
    font-style: italic;
  }

  // Generic.Deleted
  .gd {
    color: #000;
    background-color: #fdd
  }

  // Generic.Deleted.Specific
  .gd .x {
    color: #000;
    background-color: #faa
  }

  // Generic.Emph
  .ge {
    font-style: italic
  }

  // Generic.Error
  .gr {
    color: $error-color;
  }

  // Generic.Heading
  .gh {
    font-weight: $monospace-font-strong-weight;
  }

  // Generic.Inserted
  .gi {
    color: #000;
    background-color: #dfd
  }

  // Generic.Inserted.Specific
  .gi .x {
    color: #000;
    background-color: #afa
  }

  // Generic.Output
  .go {
    color: $text-color-light;
  }

  // Generic.Prompt
  .gp {
    font-weight: $monospace-font-strong-weight;
  }

  // Generic.Strong
  .gs {
    font-weight: $monospace-font-strong-weight;
  }

  // Generic.Subheading
  .gu {
  }

  // Generic.Traceback
  .gt {
    color: $error-color;
  }

  // Keyword.Constant
  .kc {
    font-weight: $monospace-font-strong-weight;
  }

  // Keyword.Declaration
  .kd {
    font-weight: $monospace-font-strong-weight;
  }

  // Keyword.Pseudo
  .kp {
    font-weight: $monospace-font-strong-weight;
  }

  // Keyword.Reserved
  .kr {
    font-weight: $monospace-font-strong-weight;
  }

  // Keyword.Type
  .kt {
    color: $name-color;
    font-weight: $monospace-font-strong-weight;
  }

  // Literal.Number
  .m {
    color: $literal-number-color;
    font-weight: $monospace-font-strong-weight;
  }

  // Literal.String
  .s {
    color: $literal-string-color;
  }

  // Name.Attribute
  .na {
    color: $name-color;
  }

  // Name.Builtin
  .nb {
    color: $name-color;
  }

  // Name.Class
  .nc {
    color: $name-color;
    font-weight: $monospace-font-strong-weight;
  }

  // Name.Constant
  .no {
    color: $name-color;
  }

  // Name.Entity
  .ni {
    color: $name-color;
  }

  // Name.Exception
  .ne {
    color: $name-color;
    font-weight: $monospace-font-strong-weight;
  }

  // Name.Function
  .nf {
    color: $name-color;
    font-weight: $monospace-font-strong-weight;
  }

  // Name.Namespace
  .nn {
    color: $name-color;
  }

  // Name.Tag
  .nt {
    color: $name-color;
  }

  // Name.Variable
  .nv {
    color: $name-color;
  }

  // Operator.Word
  .ow {
    font-weight: $monospace-font-strong-weight;
  }

  // Text.Whitespace
  .w {
    color: #bbb;
  }

  // Literal.Number.Float
  .mf {
    color: $literal-number-color;
  }

  // Literal.Number.Hex
  .mh {
    color: $literal-number-color;
  }

  // Literal.Number.Integer
  .mi {
    color: $literal-number-color;
  }

  // Literal.Number.Oct
  .mo {
    color: $literal-number-color;
  }

  // Literal.String.Backtick
  .sb {
    color: $literal-string-color;
  }

  // Literal.String.Char
  .sc {
    color: $literal-string-color;
  }

  // Literal.String.Doc
  .sd {
    color: $literal-string-color;
  }

  // Literal.String.Double
  .s2 {
    color: $literal-string-color;
  }

  // Literal.String.Escape
  .se {
    color: $literal-string-color;
  }

  // Literal.String.Heredoc
  .sh {
    color: $literal-string-color;
  }

  // Literal.String.Interpol
  .si {
    color: $literal-string-color;
  }

  // Literal.String.Other
  .sx {
    color: $literal-string-color;
  }

  // Literal.String.Regex
  .sr {
    color: $literal-special-color;
  }

  // Literal.String.Single
  .s1 {
    color: $literal-string-color;
  }

  // Literal.String.Symbol
  .ss {
    color: $literal-special-color;
  }

  // Name.Builtin.Pseudo
  .bp {
    color: $name-color;
  }

  // Name.Variable.Class
  .vc {
    color: $name-color;
  }

  // Name.Variable.Global
  .vg {
    color: $name-color;
  }

  // Name.Variable.Instance
  .vi {
    color: $name-color;
  }

  // Literal.Number.Integer.Long
  .il {
    color: $literal-number-color;
  }
}
