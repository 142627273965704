@use "sass:math";
@use "minima" as *;
@use "minima/base" as *;

/**
 * Site header
 */
.site-header {
  background: $background-color-light;
  border-bottom: $border-heavy;
  border-top: 0;

  .wrapper {
    padding-top: math.div($spacing-unit, 2);
    padding-bottom: math.div($spacing-unit, 2);

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0 $spacing-unit;
    align-items: baseline;
  }
}

.site-title {
  font-weight: $base-font-weight-strong;
  font-size: 1.2rem;

  &,
  &:visited {
    color: $text-color;
  }
}

.site-nav {
  .page-links {
    display: flex;
    flex-wrap: wrap;
    gap: 0 math.div($spacing-unit, 2);

    .page-link {
      color: $text-color;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  background: $background-color-light;
  border-top: $border-heavy;
  padding: $spacing-unit 0;
}

.footer-heading {
  font-size: 1.125rem;
  margin-bottom: math.div($spacing-unit, 2);
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: $small-font-size;
  color: $text-color-light;
  display: grid;
  grid-template-columns: auto auto 1fr;
  justify-content: space-between;
  gap: 0 (2 * $spacing-unit);

  @include media-query($on-laptop) {
    // Collapse to two columns
    grid-template-columns: 1fr 1fr;

    // If the number of "cells" in the grid (.footer-col elements) is odd, have
    // the last cell span the full width:
    .footer-col:last-child:nth-child(odd) {
      grid-column: 1/-1;
    }
  }

  @include media-query($on-palm) {
    // Collapse to one column
    grid-template-columns: 1fr;
  }
}



/**
 * Page content
 */
.page-content {
  padding: ($spacing-unit * 3) 0;
  flex: 1;
}

.page-heading {
  font-size: 2rem;
}

.post-list-heading {
  font-size: 1.75rem;
}

.post-list {
  margin-left: 0;

  >.post-list-item {
    // Amount of space between posts on homepage
    margin-bottom: $spacing-unit * 3;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $text-color-light;
}

.post-link {
  display: block;
}



/**
 * Prev/next page switcher
 */
.page-switcher {
  display: flex;
  justify-content: space-between;
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  font-size: 2.2rem;
  letter-spacing: -0.5px;
  line-height: 1;

  @include media-query($on-laptop) {
    font-size: 2.2rem;
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.2rem;
    margin-bottom: math.div($spacing-unit, 4);
  }

  h3 {
    font-size: 1rem;
    font-weight: $base-font-weight-strong;
    margin-bottom: math.div($spacing-unit, 4);
  }

  // Others unused
}


/**
 * Text indicating that a post has more to read.
 */
p.excerpt-warning {
  font-size: $small-font-size;
  margin-top: -0.5em;
}

/**
 * Atom feed preview
 * Same styling is used on the "Browse" page
 */
ul.atom-post-list {
  li .atom-post-list-entry span {
    white-space: nowrap;
  }
}
